export default {
  hello: "Hello",
  login: "Login",
  logout: "Logout",
  register: "Register",
  or: "or",
  freeTools: "Basic Tools",
  guest: 'Guest',
  tradingNews: 'News Trading',
  analysis: "Analysis",
  education: "Education",
  feed: "Feed",
  readmore: "Read More",
  newpost: "New Post",
  nopost: "No post found",
  notFound: "Page not found",
  packages: "Packages",
  gold: "Gold",
  silver: "Silver",
  oil: "Oil",
  notData: "No data yet. Stay tuned.",
  freeTrainings: "Free Trainings",
  paidTrainings: "Paid Trainings",
  liveTrainings: "Live Trainings",
  educationText:
    "Analysis of the total value of the cryptocurrency market. Here The analyzes contained are not investment advice. just education It is purposeful. Make your investment decisions based on your own research. Please give. It is not investment advice.",
  popular: "Popular",
  educations: "Educations",
  popularEducationsText:
    "Some of our popular trainings are listed below. You can visit our education page for more education.",
  free: "Free",
  alarms: "Alarms",
  alarmsDescription:
    "The analyzes contained herein are not investment advice. It is for educational purposes only. Make your investment decisions based on your own research. Please do not take it as advice.",
  noAlarms: "Content not found...",
  instructor: "Instructor",
  popularEducations: "Popular Educations",
  privacy_policy: "Privacy Policy",
  analysisdescription:
    "Welcome to the Analysis. You can review the analyzes we have prepared for you. The information contained in our analyzes is not investment advice. just education It is purposeful. Make your investment decisions based on your own research. Please give. It is not investment advice.",

  goBackAnalysis: "Go Back Analysis",
  analysisPageDescription:
    "The information contained in our analyzes is not investment advice. just education It is purposeful. Make your investment decisions based on your own research. Please give. It is not investment advice.",

  shortTerm: "Short Term",
  longTerm: "Long Term",
  supportResistance: "Support Resistance",
  majorFactors: "Macro Analysis",

  questionAnswer: "Question Answer",
  questionAnswerDescription:
    " Here you can ask your questions. Your questions can be answered by other users. Your questions will be published after admin approval.",
  noQuestionAnswer: "Content not found...",
  askQuestion: "Ask Question",
  planToAskAndAsnwer: "To be able to ask or answer questions, you need to have purchased at least one plan.",
  warningInforQuestion: `Your questions will be published after admin approval. Before asking a question, please read the question rules.`,
  questionRules: `Question Rules`,
  isAdminQuestion:
    "*You are logged in as admin. You can confirm the questions. Click the confirm button to confirm.",

  Answer: "Answer",
  loginRequired: "Login required to see the page content",
  membershipRequired: "You must have %s plan to see related analysis. Click to buy the plan!",
  seePackages: "See Packages",
  comingSoon: 'Coming Soon!',
  clickMoreDetails: 'Click to see more details!',
  inProgressUnderConstructuor: 'Under constructor! Preparing for you..',
  currency: 'USD',
  close: 'Close',
  details: 'Details',
  welcome: 'Welcome',
  welcomeMemberOrGuest: 'Welcome to the Traderedit world. You can follow news and developments about the stock exchanges from here. We will do our best to provide you with the most up-to-date news.',
  getStarted: 'Get Started',
  exit: 'Exit',
  exitText: 'Are you sure you want to logout?',
  yes: 'Yes',
  sessionModified: 'New session detected. Logging out..',
  time: 'Time',
  volume: 'Volume',
  walletAddress: "Wallet Address",
  search: 'Search',
  resetPassword: 'Reset Password',

  analytics: {
    title: "Analysis",
    bitcoin: "Bitcoin",
    ethereum: "Ethereum",
    totalIndices: "Total Indices",
    domIndices: "Dominance Indices",
    subCoins: "Alt Coin",
    onChain: "On Chain",
    breakAndIncom: "Breakdowns and Incompatibilities",
  },

  homepage: {
    title: "TraderEdit™",
    description:
      "TraderEdit™ is a platform that allows users to learn about all stock market data using world market data.",
    keywords: "TraderEdit™, Stock Market, Crypto, Forex, All Markets",
    menu: {
      home: "Home",
      feed: "Feed",
      analysis: "Analysis",
      aboutus: "About Us",
      education: "Educations",
      contact: "Contact",
      freeTools: "Basic Tools",
      tradingNews: 'News Trading',
    },
    avatarDropdown: {
      hello: "Hello",
      dashboard: "Dashboard",
      feed: "Feed",
      pricing: "Pricing",
      profile: "Profile",
      support: "Support",
      signout: "Sign Out",
      login: "Sign In",
      register: "Sign Up",
      analysis: "Analysis",
    },
  },

  loginPage: {
    title: "Login",
    emailorusername: "Email",
    password: "Password",
    login: "Sign In",
    register: "Sign Up",
    forgotpassword: "Forgot Password?",
    metamaskLogin: "Login via Metamask",
    dontHaveAccount: "Do you want to register with e-mail?",
    signUpFree: "Sign Up Free",
  },

  registerPage: {
    name: "Name",
    surname: "Surname",
    username: "Username",
    email: "Email",
    password: "Password",
    confrimPassword: "Confirm Password",
    register: "Register",
    login: "Login",
    alreadyHaveAccount: "Already have an account?",
    title: "Register",
    description: "Register",
    keywords: "Register",
  },

  cryptoPage: {
    crypto: "Crypto",
    bitcoin: "Bitcoin",
    ethereum: "Ethereum",
    totalIndices: "Total Indices",
    domIndices: "Dominance Indices",
    subCoins: "Alt Coin",
    onChain: "On Chain",
    breakAndIncom: "Breakdowns and Incompatibilities",
  },

  forexPage: {
    gold: "Gold",
    silver: "Silver",
    oil: "Oil",
    breakAndIncom: "Breakdowns and Incompatibilities",
  },

  teamsPage: {
    ourTeam: 'Our Team',
    ourAwesomeTeam: 'Our Awesome Team',
    desc: 'There are many variations of passages of our team!',
    resume: 'Resume',
    erce_arslan: "Erce Arslan graduated from Istanbul Technical University, Department of Geological Engineering. Through his interest in statistics and mathematics, he started playing professional poker in 2010 and earned many trophies. He got into stock market as a trader in 2017. Since 2019, he focused his studies on Elliott Wave Analysis and Algorithmic Trading professionally, obtained international licenses and gave training courses. He has been working as chief analyst since 2021. Erce Arslan is also one of the most well-known musicians of the country, which he pursues as a hobby.",
    emre_islek: "Emre İşlek graduated from Marmara University, Department of Economics. He started his career in the finance department of a casino in Cyprus in 2006. Since then, he has worked as manager in finance, risk management and marketing departments in casino industry. He entered the capital markets professionally in 2017. He has been providing analysis, consultancy and research services in his own companies since 2020. He also organizes broadcasts, courses and seminars on markets, investment psychology, macro economy, economic history and crypto assets on TV channels and universities.",
    ruslan_petrov: "Ruslan Petrov graduated from Kazan University, Department of Applied Mathematics and Informatics in 2017. He gained 3 years of working experience as a data analyst in Russia. Later, he worked as portfolio management specialist and derivatives analyst in important financial companies of Cyprus.",
    sergen_kaya: "Sergen Kaya graduated from the University of Strasbourg, Department of Law. He continues his studies on Blockchain & Data and Informatics Law while also maintaining his activities with financial markets, which he started in 2019.",
    koray_sukut: "Koray Şüküt graduated from Abant İzzet Baysal University, Department of Economics. He entered the area of finance as an accountant in 2010. He has specialized in capital markets since 2019. He has CMB level 3 License.",
    kagan_sari: "Kağan Sarı graduated from Trakya University, Department of International Trade and completed his education at Dallas-Texas Elite Language Institute. He started his career in the logistics department of Dynesic Technologies. He has been working on Elliott Wave Analysis and his studies on finance, in line with market trends and market research since 2021.",
    baris_sahin: "Barış Şahin, After Kocaeli Science High School, he graduated from Bahçeşehir University, Department of Economics and Finance with honors. He has been actively working as a Foreign Markets Director in the forex industry since 2018.",
  },

  allMarketsPage: {
    allMarkets: "All Markets",
    stockMarkets: "Stock Markets",
    parities: "Parities",
    energyMarket: "Energy Market",
    metalMarket: "Metal Market",
    commodities: "Commodities",
  },
  questionAnswerPage: {
    questionAnswer: "Question Answer",
  },

  toolsPage: {
    marketsData: "Markets Data",
    allTimeHighs:"All Time Highs",
    heatMap: "Heatmaps",
    cryptoCoinsHeatmap: "Crypto Coins Heatmap",
    stockHeatmap: "Stock Heatmap",
    forexHeatmap: "Forex Heatmap",
    volumeMonitor: "Volume Monitor",
    volumeMonitorHeader: "Bitcoin Hourly Trading Volume",
    technicalAnalysis: "Technical Analysis",
    marketOverview: "Market Overview",
    walletLookup: "Wallet Lookup",
    network: 'Network',
    currency: 'Currency',
    balance: 'Balance',
  },

  warningInfor: {
    fillProfile: "Please fill in your profile information.",
  },

  home: {
    menuName: "Home",
    welcome: ` <h2 className=" text-center text-2xl lg:text-4xl font-bold text-zinc-500">
    <div className="text-center font-extrabold text-4xl">
    Welcome to 
      <span className="text-yellow-600">TraderEdit™</span> ' e
    </div>
  </h2>`,

    subText:
      "TraderEdit™ is a platform that allows users to learn about all stock market data using world market data.",
  },

  profileDropdown: {
    hello: "Hello",
    dashboard: "Dashboard",
    pricing: "Pricing",
    profile: "Profile",
    support: "Support",
    signout: "Sign Out",
  },

  footer: {
    privacyPolicy: "Privacy Policy",
    cookiePreferences: "Cookie",
    termsOfUse: "Terms of Use",
    contact: "Contact",
    allRightsReserved: "All Rights Reserved.",
  },

  aboutUsPage: {
    title: "About Us",
    description:
      "TraderEdit™ is a platform that allows users to learn about all stock market data using world market data.",
    keywords: "TraderEdit™, Stock Market, Crypto, Forex, All Markets",
    contentText:
      "TraderEdit™ is a platform that allows users to learn about all stock market data using world market data.",
    contactUs: "Contact Us",
    contactUsText: "You can contact us by filling out the form below.",
    button: "Send Message",
    whoWeAre: 'Who we are?',
    descriptionText: `We are a financial analysis company specializing in price movements, market trends, forecasting, technical and fundamental analysis across all financial markets.\nAs Traderedit, we started working in American and crypto markets in 2020. Today, we conduct uninterrupted and up to date, short and long-term analyses on more than 40 financial market products with 8 analysts, 24 hours a day. Our analysts have the highest possible success rate in the financial markets and they are known for this aspect. We also provide educational and algotrade services as well as special research services to corporate companies.`,
  },

  tradingNewsPage: {
    title: "News Trading",
    description:
      "TraderEdit™ is a platform that allows users to learn about all stock market data using world market data.",
    keywords: "TraderEdit™, Stock Market, Crypto, Forex, All Markets",
    contentText: 'Under constructor. Latest news trading with you asap..',
    contactUs: "Contact Us",
    contactUsText: "You can contact us by filling out the form below.",
    button: "Send Message",
  },

  contactPage: {
    title: "Contact",
    description:
      "TraderEdit™ is a platform that allows users to learn about all stock market data using world market data.",
    keywords: "TraderEdit™, Stock Market, Crypto, Forex, All Markets",
    contactUs: "Contact Us",
    contactUsText: "You can contact us by filling out the form below.",
    button: "Send Message",
  },

  termsOfUse: {
    title: "Terms of Use",
    description:
      "Welcome! Please carefully read the Terms of Use for TraderEdit. These terms define the rules and conditions you must adhere to while using our website.",
    keywords: "TraderEdit™, Stock Market, Crypto, Forex, All Markets",
    contentText:
      "Welcome! Please carefully read the Terms of Use for TraderEdit. These terms define the rules and conditions you must adhere to while using our website.",
    ourTeams: "Our friendly team is here to help.",
  },

  basket: {
    price: "Price",
    basketName: "Basket",
    basketAdd: "Add to Basket",
    basketRemove: "Remove from Basket",
    addedToBasket: "Added to Basket",
    removedFromBasket: "Removed from Basket",
    basketEmpty: "Basket is empty",
    basketEmptyText:
      "You can add products to your basket by clicking the add to basket button on the product page.",
    basketEmptyButtonText: "Explore Products",
    basketTotal: "Total",
    basketCheckout: "Checkout",
    taxIncluded: "Tax Included",
    cardCheckout: "Checkout with Cart",
    coinPaymentscheckout: "Checkout with CoinPayments",
    alreadyExist: "Training already added to cart...",
    subtotal: 'Subtotal',
    discount: 'Discount',
    total: 'Total',
    couponCode: 'Coupon Code',
    apply: 'Apply'
  },

  add: 'Add',
  name: 'Name',
  edit: 'Edit',
  cancel: 'Cancel',
  delete: 'Delete',
  approve: 'Approve',
  approved: 'Approved',
  sendAnswer: 'Send Answer',
  showMore: 'Show more',
  questionAnswersPH: 'To answer this question, write in this field..',
  askQuestionPH: "To ask a question, write in this field..",
  questionAnswerSuccessMessage: 'Your question has been sent successfully. It will be published after admin approval.',
  firstName: 'First Name',
  lastName: 'Last Name',
  message: 'Message',

  dashboard: {
    welcome: 'Welcome to admin panel. You are able to manage the site here.',
    controlPanel: 'Control Panel',
    users: 'Users',
    membershipSales: 'Membership Sales',
    membershipPlans: 'Membership Plans',
    membershipPlansDesc: 'You are able to manage plans here.',
    analysis: 'Analysis',
    educations: 'Educations',
    cryptoSettings: 'Crypto Settings',
    alarms: 'Alarms',
    forexSettings: 'Forex Settings',
    forexAlarms: 'Forex Alarms',
    messages: 'Messages',
    totalUsers: 'Total Users',
    totalPosts: 'Total Posts',
    totalMembershipSales: 'Total Membership Sales',
    postsManagement: 'Analysis Post Management',
    postsDesc: 'You are able to manage analysis here.',
  },

  analysisPage: {
    mainForex: 'Main Forex',
    partities: 'Parities',
    stockMarket: 'Stock Market',
    corporateServices: 'Corporate Services',

    nseIndia:"Nse (India)",
    nikkeiJapan:"Nikkei (Japan)",
    cac40France:"Cac40 (France)",
    daxGermany:"Dax (Germany)",
    ftseLondon:"Ftse (London)",
    smiSwiss:"Smi (Swiss)",
    coffee:"Coffee",
    cotton:"Cotton",
    liveCattle:"Live Cattle",
    orangeJuice:"Orange Juice",
    corn:"Corn",
    soybean:"Soybean",
    sugar:"Sugar",
    wheat:"Wheat",

    asia: 'Asia',
    europe: 'Europe',
    usa: 'USA',
    contact: 'Please contact for details'
  },

  pricingPage: {
    pricing: 'Pricing',
    pricingPlans: 'Our Pricing Plans',
    choose: 'Choose the perfect pricing plan that suits your needs.',
    period: {
      weeks: '15 Days',
      month: '1 Month',
      months: '3 Months',
      year: 'Year'
    },
    getStarted: 'Get Started',
    buy: 'Buy',
    bought: 'Already Bought',
    select: 'Add to cart',
    selected: 'Added to cart',
    contact: 'Contact',
    aboutPlan: ' I want to get information about %s',
    boughtSuccess: 'Success. Please re-login to use new plans.'
  }
};
