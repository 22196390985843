import React, { useEffect } from "react";
import { Button } from "@nextui-org/react";
import { useRouter } from "next/router";
import { RiArrowLeftLine, RiHome7Fill } from "react-icons/ri";
import { lang } from "@lang/langT";

const UnderConstructor = () => {
  const style = {
    container: {
      backgroundColor: '#1e1e1e',
      color: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
      textAlign: 'center',
    },
    wrapper: {
        maxWidth: '600px'
    },
    h1: {
        fontSize: '2.5rem',
        marginBottom: '20px'
    },
    p: {
        fontSize: '1.2rem',
        marginBottom : '30px'
    },
    logo: {
        width: '150px',
        marginBottom: '30px'
    },
    countdown: {
        fontSize: '2rem',
        marginBottom: '20px'
    }
  }

  useEffect(()=>{
    if(typeof window === 'undefined'){
      return;
    }
     // Set the date we're counting down to
     var countDownDate = 1713200400 * 1000; // Convert to milliseconds

     // Update the countdown every 1 second
     var x = setInterval(function() {

         // Get the current date and time
         var now = new Date().getTime();

         // Calculate the distance between now and the countdown date
         var distance = countDownDate - now;

         // Calculate days, hours, minutes, and seconds
         var days = Math.floor(distance / (1000 * 60 * 60 * 24));
         var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
         var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
         var seconds = Math.floor((distance % (1000 * 60)) / 1000);

         // Display the countdown
         if(!document.getElementById("countdown")) {
          return;
         }
         document.getElementById("countdown").innerHTML = days + "d " + hours + "h "
         + minutes + "m " + seconds + "s ";

         // If the countdown is finished, display a message
         if (distance < 0) {
             clearInterval(x);
             document.getElementById("countdown").innerHTML = "EXPIRED";
         }
     }, 1000);
  },[])

  return (
    <div style={style.container} className="container under-constructor">
      <div style={style.wrapper} className="wrapper">
        <img src="/logo.png" alt="Logo" className="logo mx-auto" />
        <h1 style={style.h1}>Under Construction</h1>
        <p style={style.p}>We're working on something awesome! Please check back soon.</p>
        <div style={style.countdown} id="countdown"></div>
      </div>
    </div>
  );

};

export default UnderConstructor;
