export default class LocalStorage {
    static get(key) {
        if(typeof window !== 'undefined') {
            return window.localStorage.getItem(key);
        }
        return null;
    }
    static set(key, value) {
        if(typeof window !== 'undefined') {
            return window.localStorage.setItem(key,typeof value === 'string' ? value : JSON.stringify(value));
        }
        return null;
    }
    static remove(key) {
        if(typeof window !== 'undefined') {
            return window.localStorage.removeItem(key);
        }
        return null;
    }
}