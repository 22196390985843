import cache from 'memory-cache';
export default class MemCache {
    static set(key, value, ttl = 300000) {
        cache.put(key, value, ttl);
    }

    static get(key) {
        return cache.get(key);
    }

    static remove(key) {
        cache.del(key);
    }

    static truncate() {
        cache.clear();
    }
}
