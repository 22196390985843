import axios from "axios";
import MemCache from "@/libs/memCache";

export default class Utils {
    static trToEn(str) {
        return str.replace(/Ğ/g, "G")
		.replace(/Ü/g, "U")
		.replace(/Ş/g, "S")
		.replace(/I/g, "I")
		.replace(/İ/g, "I")
		.replace(/Ö/g, "O")
		.replace(/Ç/g, "C")
		.replace(/ğ/g, "g")
		.replace(/ü/g, "u")
		.replace(/ş/g, "s")
		.replace(/ı/g, "i")
		.replace(/ö/g, "o")
		.replace(/ç/g, "c");
    }

    static makeSlug(str) {
        return Utils.trToEn(str).substring(0, 60).trim().replace(/[^A-Za-z0-9_-\s]/g,'').replace(/\s+/g, "-").toLowerCase();
    }

	static firstLetterUpper(str) {
		if(!str) return '';
		return str[0].toUpperCase()+str.slice(1);
	}

	static maskAddress = (address) => {
		if (!address) return ''
		return (address.slice(0, 6) + '...' + address.slice(address.length - 4)).toUpperCase()
	}

	static normalizeNumber = (_num, isInt) => {
		const num = (parseFloat(_num) || 0).toFixed(3);
		if(isInt) {
			return parseInt(num);
		}
		return parseFloat(num);
	}

	static async getTlExchangeRate() {
		const {data} = await axios.get(`/api/exchange`);
		return data.data || 0;
	}

	static nestedObjectString(object, path) {
		var byString = function(o, s) {
			s = s.replace(/\[(\w+)\]/g, '.$1');
			s = s.replace(/^\./, '');
			var a = s.split('.');
			for (var i = 0, n = a.length; i < n; ++i) {
				var k = a[i];
				if (k in o) {
					o = o[k];
				} else {
					return;
				}
			}
			return o;
		}
		return byString(object, path);
	}

	static dateToCountdownString(countDownDate, lang, excludeMins) {
		var now = Math.floor(new Date().getTime() / 1000);
		var distance = parseInt(countDownDate) - now;
		var days = Math.floor(distance / (60 * 60 * 24));
		var hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
		var minutes = Math.floor((distance % (60 * 60)) / (60));
		var seconds = Math.floor(distance % 60);
		var locale = {
			tr: {
				day: 'Gün',
				hours: 'Saat',
				minutes: 'Dakika',
				seconds: 'Saniye',
				left: 'Kaldı',
				expired: 'Süresi Dolmuş!'
			},
			en: {
				day: 'Day',
				hours: 'Hours',
				minutes: 'Minutes',
				seconds: 'Seconds',
				left: 'Left',
				expired: 'Expired!'
			}
		}

		return distance < 0 ? locale[lang].expired : days + ` ${locale[lang].day} ` + hours + ` ${locale[lang].hours} ` + (excludeMins ? '' : minutes + ` ${locale[lang].minutes} ` + seconds + ` ${locale[lang].seconds} `) + locale[lang].left;
	}

	static currentTime() {
		return Math.floor(new Date().getTime()/1000);
	}

	static numberWithCommas(num){
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
}