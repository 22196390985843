import "@/styles/globals.scss";
import {SessionProvider} from "next-auth/react";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Wrapper} from "@/context";
import {NextUIProvider} from "@nextui-org/react";
import {ThemeProvider as NextThemesProvider} from "next-themes";
import axios from "axios";
import 'swiper/css';
import Menu from "@/components/menu";
import UnderConstructor from "./under_constructor";
import { useRouter } from "next/router";


export default function App({
                            Component,
                              pageProps: {session, ...pageProps},
                            }) {

  axios.defaults.baseURL = process.env.NEXT_PUBLIC_APP_URL;
  const router = useRouter();

  // if(router.query.dev !== '1' && process.env.NODE_ENV === 'production'){
  //   return (
  //     <UnderConstructor />
  //   )
  // }

  return (
    <SessionProvider session={session}>
      <Wrapper>
        <Menu/>
        <NextUIProvider>
          <NextThemesProvider attribute="class" defaultTheme="dark">
            <Component {...pageProps} />
          </NextThemesProvider>
        </NextUIProvider>
        <ToastContainer/>
      </Wrapper>
    </SessionProvider>
  );
}
