import LocalStorage from "@/libs/localStorage";
import {createContext, useContext, useState} from "react";

const AppContext = createContext();

export function Wrapper({children}) {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [isServiceLoading, setIsServiceLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [basket, _setBasket] = useState([]);
  const setBasket = (param) => {
    LocalStorage.set('basket', JSON.stringify(param));
    _setBasket(param);
  }
  const sharedState = {
    isOpenSidebar,
    setIsOpenSidebar,
    isServiceLoading,
    setIsServiceLoading,
    loading,
    setLoading,
    basket,
    setBasket,
  };

  return (
    <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
