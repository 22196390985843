import React, { useEffect, useInsertionEffect, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Spinner,
  User,
} from "@nextui-org/react";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import LangDropdown from "@components/langDropdown";
import { lang } from "@/lang/langT";
import { RiUserAddLine, RiUserFill } from "react-icons/ri";
import Basket from "@components/basket";
import { PiUserDuotone } from "react-icons/pi";
import Swal from "sweetalert2";

const Menu = () => {
  const { locale, push, pathname } = useRouter();
  const { data: session, status } = useSession();
  const t = lang(locale);
  const [isMobile, setIsMobile] = useState(false);

  const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), timeout);
    };
  }
  
  const setView = () => {
    setIsMobile(window.innerWidth <= 1024);
  }

  useEffect(()=>{
    if(typeof window !== 'undefined') {
      setView();
      window.addEventListener('resize', debounce(setView));
    }
    return ()=> window.removeEventListener('resize', debounce(setView));
  }, []);

  // { path: "/", label: "home" },
  const menuItems = [
    { path: "/analysis", label: "analysis" },
    { path: "/about-us", label: "aboutus" },
    { path: "/education", label: "education" },
    { path: "/tools", label: "freeTools" },
    { path: "/trading-news", label: "tradingNews" },
    { path: "/contact", label: "contact" },
  ];

  return (
    <Navbar
      maxWidth="2xl"
      position="sticky"
      isBordered
      isBlurred
      className={"py-2"}
      style={{backgroundColor: (pathname === '/' || pathname.indexOf('about-us') !== -1) ? '#000' : '#27272a'}}
    >
      <NavbarBrand style={{flexGrow: 'unset', marginRight: '1rem', minWidth: 'fit-content'}}>
        <Link className={`flex flex-row ${isMobile?'gap-[6px]':'gap-[8px]'}`} href="/">
          <img style={{width: 'auto', height: (isMobile?'36px':'48px')}} src="/logo.png" />
          <div className="w-[1px] h-[28px] bg-white"></div>
          <h1 className="text-inherit !text-[#ebb305]">TraderEdit</h1> 
        </Link>
      </NavbarBrand>
      <NavbarContent className="hidden hs:flex gap-4 md:gap-8" justify="center">
        {menuItems.map(({ path, label }) => (
          <NavbarItem key={path} isActive={pathname === path} path={path}>
            <Link color={pathname !== path ? "foreground" : ""} href={path}>
              {t.homepage.menu[label]}
            </Link>
          </NavbarItem>
        ))}
      </NavbarContent>
      <NavbarContent justify="end" className={"gap-1"}>
        {status === "authenticated" ? <Basket /> : null} 

        <LangDropdown />
        {status === "authenticated" ? (
          <>
            <ProfileOptions t={t} session={session} isMobile={isMobile} push={push} /> 
            <MobileMenu t={t}  menuItems={menuItems} pathname={pathname}/>
          </>
        ) : status === "loading" ? (
          <Spinner />
        ) : (
          <>
            <Dropdown backdrop={"blur"} showArrow variant="faded">
              <DropdownTrigger>
                <Button
                  isIconOnly={true}
                  variant={"light"}
                  className={
                    "w-10 h-10 p-1 flex " + " justify-center" + " items-center"
                  }
                >
                  <PiUserDuotone fontSize={22} className={"text-white"} />
                </Button>
              </DropdownTrigger>
              <DropdownMenu variant="faded" aria-label="Static Actions">
                <DropdownItem
                  className={"duration-300"}
                  onClick={() => push("/auth/login")}
                >
                  <div className={"flex items-center gap-3 py-2 px-2 "}>
                    <RiUserFill fontSize={24} />
                    <span> {t.homepage.avatarDropdown.login}</span>
                  </div>
                </DropdownItem>
                <DropdownItem
                  variant={"bordered"}
                  color={"warning"}
                  className={"duration-300"}
                  onClick={() => push("/auth/register")}
                >
                  <div className={"flex items-center gap-3 py-1.5 px-2"}>
                    <RiUserAddLine fontSize={24} />
                    <span> {t.homepage.avatarDropdown.register}</span>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <MobileMenu t={t}  menuItems={menuItems} pathname={pathname}/>
          </>
        )}
      </NavbarContent>
    </Navbar>
  );
};

const MobileMenu = ({menuItems, pathname, t}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="hs:hidden ml-[8px] cursor-pointer">
      <img className="min-w-[32px]" src="/menu.svg" onClick={()=>setMobileMenuOpen(!mobileMenuOpen)} />
      {mobileMenuOpen ? <div className="fixed top-0 left-0 right-0 bottom-0 z-10 w-[100vw] h-[100vh]" onClick={()=>setMobileMenuOpen(false)}></div> : null}
      <div
      style={{backgroundColor: (pathname === '/' || pathname.indexOf('about-us') !== -1) ? '#000' : '#27272a'}}
      className={` ${mobileMenuOpen ? 'flex' : 'hidden' } flex-col items-center w-full fixed top-[81px] left-0 bg-black gap-[16px] pt-[16px] z-20`}>
        {menuItems.map(({ path, label }) => (
          <NavbarItem key={path} isActive={pathname === path} path={path}>
            <Link color={pathname !== path ? "foreground" : ""} href={path}>
              {t.homepage.menu[label]}
            </Link>
          </NavbarItem>
        ))}
        <Divider/>
      </div>
    </div>
  );
}

const ProfileOptions = ({t, session, isMobile, push}) => {
  return (
    <>
      <Dropdown
        backdrop={"blur"}
        variant="faded"
        showArrow
        placement="bottom-start"
        className={"mt-6"}
      >
        <DropdownTrigger>
          {isMobile?
            <Button
              isIconOnly={true}
              variant={"light"}
              className={
                "w-10 h-10 p-1 flex " + " justify-center" + " items-center"
              }
            >
              <PiUserDuotone fontSize={22} className={"text-white"} />
            </Button> : 
            <div className={"relative flex flex-shrink-0"}>
              <User
                as="button"
                avatarProps={{
                  isBordered: true,
                  src: session.user.image,
                }}
                className="transition-transform"
              />
            </div>
          }
        </DropdownTrigger>
        <DropdownMenu className="p-dropdown-menu" aria-label="User Actions" variant="faded">
          <DropdownItem key="profile" className="h-14 gap-2">
            <p className="font-bold">
              {session.user.name + " " + session.user.surname}
            </p>
          </DropdownItem>

          {session?.user?.role === "admin" && (
            <DropdownItem
              key="Dashboard"
              onClick={() => push("/dashboard")}
            >
              {t.homepage.avatarDropdown.dashboard}
            </DropdownItem>
          )}
          <DropdownItem key="Analysis" onClick={() => push("/analysis")}>
            {t.homepage.avatarDropdown.analysis}
          </DropdownItem>
          <DropdownItem key="Profile" onClick={() => push("/feed/profile")}>
            {t.homepage.avatarDropdown.profile}
          </DropdownItem>
          <DropdownItem key="Pricing" onClick={() => push("/feed/pricing")}>
            {t.homepage.avatarDropdown.pricing}
          </DropdownItem>
          <DropdownItem
            key="logout"
            color="danger"
            onClick={() => {
              Swal.fire({
                title: t.exit,
                text: t.exitText,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: t.yes,
                cancelButtonText: t.cancel,
              }).then((result) => {
                if (result.isConfirmed) {
                  signOut();
                }
              });
            }}
          >
            {t.homepage.avatarDropdown.signout}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default Menu;
