import { useEffect, useState } from "react";
import Utils from "@/utils/utils";
import { useRouter } from "next/router";

const usePrice = () => {
    const { locale } = useRouter();
    const [exchangeRate, setExchangeRate] = useState(0);
    const fetchData = async (_pUSD) => {
        const excr = await Utils.getTlExchangeRate();
        setExchangeRate(excr);
    };
    useEffect(()=>{
        fetchData();
    },[locale]);
    const getPrice = (_price, isText=true, isInt=true) => {
        let res = 0;
        if(locale === 'tr' && exchangeRate) {
            res = Utils.normalizeNumber(_price * exchangeRate, isInt);
            return isText ? Utils.numberWithCommas(res)+'TL' : res;
        } else {
            res = Utils.normalizeNumber(_price, isInt);
            return isText ? '$'+Utils.numberWithCommas(res) : res;
        }
    };
    return { getPrice };
}

export default usePrice;