export default {
  hello: "Merhaba",
  login: "Giriş Yap",
  logout: "Çıkış Yap",
  register: "Kayıt Ol",
  or: "veya",
  freeTools: "Temel Araçlar",
  guest: 'Misafir',
  tradingNews: 'Haber Trade',
  analysis: "Analiz",
  education: "Eğitim",
  feed: "Akış",
  readmore: "Devamını Oku",
  newpost: "Yeni İçerik",
  nopost: "İçerik bulunamadı",
  notFound: "Sayfa bulunamadı",
  packages: "Paketler",
  gold: "Altın",
  silver: "Gümüş",
  oil: "Petrol",
  notData: "Henüz içerik oluşturulmamıştır. Takipte kalın.",
  freeTrainings: "Ücretsiz Eğitimler",
  paidTrainings: "Ücretli Eğitimler",
  liveTrainings: "Canlı Eğitimler",
  educationText:
    "Kripto para piyasasının toplam değerinin analizi. Burada yer alan analizler yatırım tavsiyesi değildir. Sadece eğitim amaçlıdır. Yatırım kararlarınızı kendi araştırmalarınız sonucunda veriniz. Lütfen dikkate almayınız. Yatırım tavsiyesi değildir.",
  popular: "Popüler",
  educations: "Eğitimler",
  popularEducationsText:
    "Popüler eğitimlerimizden bazıları aşağıda yer almaktadır. Daha fazla eğitim için eğitim sayfamızı ziyaret edebilirsiniz.",
  free: "Ücretsiz",
  alarms: "Alarmlar",
  alarmsDescription:
    "Burada yer alan analizler yatırım tavsiyesi değildir. Sadece eğitim amaçlıdır. Yatırım kararlarınızı kendi araştırmalarınız sonucunda veriniz. Lütfen dikkate almayınız. Yatırım tavsiyesi değildir.",
  noAlarms: "İçerik bulunamadı...",
  instructor: "Eğitmen",
  popularEducations: "Popüler Eğitimler",
  privacy_policy: "Gizlilik Politikası",
  analysisdescription:
    "Analizlere hoş geldiniz. Analizlerinizi sizler için hazırladığımız analizleri inceleyebilirsiniz. Analizlerimizde yer alan bilgiler yatırım tavsiyesi değildir. Sadece eğitim amaçlıdır. Yatırım kararlarınızı kendi araştırmalarınız sonucunda veriniz. Lütfen dikkate almayınız. Yatırım tavsiyesi değildir.",

  goBackAnalysis: "Analizlere Geri Dön",
  analysisPageDescription:
    "Analizlerimizde yer alan bilgiler yatırım tavsiyesi değildir. Sadece eğitim amaçlıdır. Yatırım kararlarınızı kendi araştırmalarınız sonucunda veriniz. Lütfen dikkate almayınız. Yatırım tavsiyesi değildir.",

  shortTerm: "Kısa Vadeli",
  longTerm: "Uzun Vadeli",
  supportResistance: "Destek Direnç",
  majorFactors: "Makro Analiz",

  questionAnswer: "Soru Cevap",
  questionAnswerDescription:
    "Burada sorularınızı sorabilirsiniz. Diğer kullanıcılar tarafından sorularınıza cevap verilebilir. Sorularınız admin onayından sonra yayınlanacaktır.",
  noQuestionAnswer: "İçerik bulunamadı...",
  askQuestion: "Soru Sor",
  planToAskAndAsnwer: "Soru sorabilmek veya cevaplayabilmek icin en az bir plan satın almış olmanız gerekmektedir.",
  warningInforQuestion: `Sorularınız admin onayından sonra yayınlanacaktır. Soru sormadan önce soru kurallarını okumanızı rica ederiz.`,
  questionRules: `Soru Kuralları`,
  isAdminQuestion: `*Admin olarak giriş yaptınız. Soruları onaylayabilirsiniz. Onaylamak için onayla butonuna tıklayınız.`,
  Answer: "Yanıtla",
  loginRequired: "Sayfa içeriğini görebilmek için giriş yapın",
  membershipRequired: "İlgili analizleri gormek icin %s paketine sahip olmanız gerekmektedir. Paketi hemen satın almak için tıklayın!",
  seePackages: "Paketleri Gör",
  comingSoon: 'Cok Yakında!',
  clickMoreDetails: 'Daha fazla bilgi için tıklayınız',
  inProgressUnderConstructuor: 'Yapım Aşamasında..',
  currency: 'TL',
  close: 'Kapat',
  details: 'Detaylar',
  welcome: 'Hoş Geldin',
  welcomeMemberOrGuest: 'Traderedit dünyasına hoşgeldin. Borsalar ilgili haberleri ve gelişmeleri buradan takip edebilirsin. Sana en güncel haberleri sunmak için elimizden geleni yapacağız.',
  getStarted: 'Başla',
  exit: 'Çıkış',
  exitText: 'Çıkış yapmak istediğinize emin misiniz?',
  yes: 'Evet',
  sessionModified: 'Yeni oturum tespit edildi. Çıkış yapılıyor..',
  time: 'Zaman',
  volume: 'Hacim',
  walletAddress: "Cüzdan Addresi",
  search: 'Ara',
  resetPassword: 'Şifre Yenile',


  analytics: {
    title: "Analiz",
    bitcoin: "Bitcoin",
    ethereum: "Ethereum",
    totalIndices: "Total Endeksler",
    domIndices: "Dominans Endeksler",
    subCoins: "Altcoinler",
    onChain: "On Chain",
    breakAndIncom: "Kırılımlar ve Uyumsuzluklar",
  },

  homepage: {
    title: "TraderEdit™",
    description:
      "TraderEdit™, dünya piyasa verilerini kullanarak, kullanıcıların tüm borsa verileri hakkında bilgi edinmelerini sağlayan bir platformdur.",
    keywords: "TraderEdit™, Borsa, Kripto, Forex, Tüm Piyasalar",
    menu: {
      home: "Anasayfa",
      feed: "Akış",
      analysis: "Analiz",
      aboutus: "Hakkımızda",
      education: "Eğitimler",
      contact: "İletişim",
      freeTools: "Temel Araçlar",
      tradingNews: 'Haber Trade',
    },
    avatarDropdown: {
      hello: "Merhaba",
      dashboard: "Yönetim Paneli",
      feed: "Akış",
      pricing: "Fiyatlandırma",
      profile: "Profil",
      support: "Destek",
      signout: "Çıkış Yap",
      login: "Giriş Yap",
      register: "Kayıt Ol",
      analysis: "Analiz",
    },
  },

  loginPage: {
    title: "Giriş Yap",
    emailorusername: "Eposta",
    password: "Şifre",
    login: "Giriş Yap",
    register: "Kayıt Ol",
    forgotpassword: "Şifremi Unuttum?",
    metamaskLogin: "Metamask ile Giriş",
    dontHaveAccount: "E-posta ile kayıt olmak ister misiniz?",
    signUpFree: "Ücretsiz Kayıt Ol",
  },

  registerPage: {
    name: "Ad",
    surname: "Soyad",
    username: "Kullanıcı Adı",
    email: "Eposta",
    password: "Şifre",
    confrimPassword: "Şifreyi Onayla",
    register: "Kayıt Ol",
    login: "Giriş Yap",
    alreadyHaveAccount: "Zaten bir hesabınız var mı?",
    title: "Kayıt Ol",
    description: "Kayıt Ol",
    keywords: "Kayıt Ol",
  },

  cryptoPage: {
    crypto: "Kripto",
    bitcoin: "Bitcoin",
    ethereum: "Ethereum",
    totalIndices: "Total Endeksler",
    domIndices: "Dominans Endeksler",
    subCoins: "Altcoinler",
    onChain: "On Chain",
    breakAndIncom: "Kırılımlar ve Uyumsuzluklar",
  },

  forexPage: {
    gold: "Altın",
    silver: "Gümüş",
    oil: "Petrol",
    breakAndIncom: "Kırılımlar ve Uyumsuzluklar",
  },

  warningInfor: {
    fillProfile: "Lütfen profil bilgilerinizi doldurun.",
  },

  allMarketsPage: {
    allMarkets: "Tüm Piyasalar",
    stockMarkets: "Dünya Borsaları",
    parities: "Pariteler",
    energyMarket: "Enerji Piyasası",
    metalMarket: "Metal Piyasası",
    commodities: "Emtialar",
  },

  questionAnswerPage: {
    questionAnswer: "Soru Cevap",
  },

  toolsPage: {
    marketsData: "Borsa Verileri",
    allTimeHighs: "En Yuksek Deger",
    heatMap: "Sıcaklık Haritaları",
    cryptoCoinsHeatmap: "Kripto Para Sıcaklık Haritası",
    stockHeatmap: "Borsa Sıcaklık Haritası",
    forexHeatmap: "Forex Sıcaklık Haritası",
    volumeMonitor: "Hacim İzleyici",
    volumeMonitorHeader: "Bitcoin Saatlik İşlem Hacmi",
    technicalAnalysis: "Teknik Analiz",
    marketOverview: "Piyasa Analizi",
    walletLookup: "Cüzdan Sorgulama",
    network: 'Ağ',
    currency: 'Birim',
    balance: 'Bakiye',
  },

  home: {
    menuName: "Anasayfa",
    welcome: ` <h2 className=" text-center text-2xl lg:text-4xl font-bold text-zinc-500">
    <div className="text-center font-extrabold text-4xl">
      <span className="text-yellow-600">TraderEdit™</span> ' e
    </div>
    Hoşgeldiniz
  </h2>`,
    subText:
      " TraderEdit™, dünya piyasa verilerini kullanarak, kullanıcıların tüm borsa verileri hakkında bilgi edinmelerini sağlayan bir platformdur.",
  },

  profileDropdown: {
    hello: "Merhaba",
    dashboard: "Yönetim Paneli",
    pricing: "Fiyatlandırma",
    profile: "Profil",
    support: "Destek",
    signout: "Çıkış Yap",
  },

  footer: {
    privacyPolicy: "Gizlilik Politikası",
    cookiePreferences: "Çerezler",
    termsOfUse: "Kullanım Şartları",
    contact: "İletişim",
    allRightsReserved: "Tüm Hakları Saklıdır.",
  },

  aboutUsPage: {
    title: "Hakkımızda",
    description:
      "TraderEdit™, dünya piyasa verilerini kullanarak, kullanıcıların tüm borsa verileri hakkında bilgi edinmelerini sağlayan bir platformdur.",
    keywords: "TraderEdit™, Borsa, Kripto, Forex, Tüm Piyasalar",
    contentText:
      "TraderEdit™, dünya piyasa verilerini kullanarak," +
      " kullanıcıların tüm borsa verileri hakkında bilgi edinmelerini sağlayan bir platformdur.",
    contactUs: "İletişime Geçin",
    contactUsText:
      "Sorularınız ve daha detaylı bilgi için bizimle iletişime geçin.",
    button: "Mesaj Gönder",
    whoWeAre: 'Biz kimiz?',
    descriptionText: `Biz, tüm finansal piyasalarda fiyat hareketleri, trendler, öngörü, teknik ve temel analiz konusunda uzmanlaşmış bir finansal analiz şirketiyiz.\nTraderedit olarak çalışmalarımıza 2020 yılında amerikan piyasaları ve kripto piyasaları ile başlamıştık. Bugün ise 8 analist ile 40'tan fazla finansal piyasa ürününde, 24 saat kesintisiz ve güncellenen şekilde kısa ve uzun vadeli analizler yapıyoruz. Finansal piyasalarda mümkün olan en yüksek başarı oranına sahip, bu yönden tanınmış analistlere sahibiz. Ayrıca eğitim hizmetleri, algotrade hizmetleri, ve kurumsal şirketlere özel araştırma hizmetleri vermekteyiz.`,
  },

  tradingNewsPage: {
    title: "Haber Trade",
    description:
      "TraderEdit™, dünya piyasa verilerini kullanarak, kullanıcıların tüm borsa verileri hakkında bilgi edinmelerini sağlayan bir platformdur.",
    keywords: "TraderEdit™, Borsa, Kripto, Forex, Tüm Piyasalar",
    contentText: 'Yapım aşamasında. Çok yakında güncel haberler sizlerle..',
    contactUs: "İletişime Geçin",
    contactUsText:
      "Sorularınız ve daha detaylı bilgi için bizimle iletişime geçin.",
  },

  contactPage: {
    title: "İletişim",
    description:
      "TraderEdit™, dünya piyasa verilerini kullanarak, kullanıcıların tüm borsa verileri hakkında bilgi edinmelerini sağlayan bir platformdur.",
    keywords: "TraderEdit™, Borsa, Kripto, Forex, Tüm Piyasalar",
    contactUs: "İletişime Geçin",
    contactUsText:
      "Sorularınız ve daha detaylı bilgi için bizimle iletişime geçin.",
    button: "Mesaj Gönder",
    ourTeams: "Dost canlısı ekibimiz yardıma hazır.",
  },

  termsOfUse: {
    title: "Kullanım Şartları",
    description:
      "Hoş geldin! Lütfen TraderEdit Kullanım Koşullarını dikkatlice okuyun. Bu şartlar, web sitemizi kullanırken uymanız gereken kural ve koşulları tanımlar.",
    keywords: "Trader Edit, Borsa, Kripto, Forex, Tüm Piyasalar",
  },

  teamsPage: {
    ourTeam: 'Takımımız',
    ourAwesomeTeam: 'Ekibimizle Tanışın!',
    desc: 'Ekibimizin pasajlarının birçok çeşidi var!',
    resume: 'Özgeçmiş',
    erce_arslan: "Erce Arslan, İstanbul Teknik Üniversitesi Jeoloji Mühendisliği Bölümü'nden mezun oldu. İstatistik ve matematik alanındaki ilgisi ile 2010 yılında profesyonel poker oyunculuğuna başladı ve çok sayıda derece kazandı. 2017 yılında borsa hayatına trader olarak başladı. 2019 yılından bu yana yoğunluklu olarak Elliott Dalga Analizi ve Algoritmik Trade üzerine profesyonel olarak çalışmalarını sürdürmüş, uluslararası lisanslar almış ve eğitimler vermiştir. 2021’den beri bulunduğu kurumda baş analist olarak çalışmaktadır. Erce Arslan aynı zamanda hobi olarak devam ettirdiği müzik hayatında da ülkenin en tanınmış müzisyenlerinden birisidir.",
    emre_islek: "Emre İşlek, Marmara üniversitesi İktisat bölümü mezunudur. İş hayatına 2006 yılında kıbrısta bir casinonun finans departmanında başladı. O zamandan beri casino sektöründe finans, risk yönetimi ve marketing departmanlarında yöneticilik yaptı. Sermaye piyasalarına profesyonel olarak 2017 yılında girdi. 2020 yılından beri kendi şirketlerinde analiz, danışmanlık ve araştırma hizmetleri veriyor. TV kanallarında ve üniversitelerde piyasalar, yatırım psikolojisi, makro ekonomi, iktisat tarihi ve kripto varlıklar hakkında yayınlar, eğitimler ve  seminerler düzenlemektedir.",
    ruslan_petrov: "Ruslan Petrov, Kazan Üniversitesi Uygulamalı Matematik ve Bilişim Bölümü'nden 2017 yılında mezun oldu. Rusya’da data analisti olarak 3 yıl tecrübe kazandı.Ardından Kıbrıs’ta önemli finans şirketlerinde portföy yönetim uzmanı ve türev ürünler analisti olarak çalışmıştır.",
    sergen_kaya: "Sergen Kaya, Strasbourg Üniversitesi Hukuk Bölümü mezunudur. Blockchain & Veri ve Bilişim Hukuku üzerine çalışmalarını sürdürmekte ve aynı zamanda 2019 yılında başladığı finansal piyasalarla olan faaliyetlerini sürdürmektedir.",
    koray_sukut: "Koray Süküt, Abant İzzet Baysal Üniversitesi İktisat Bölümü'nden mezun oldu. Finans hayatına 2010 yılında muhasebeci olarak başlamıştır. 2019 yılından beri sermaye piyasalarında uzmanlaşmıştır. SPK Düzey 3 Lisansına sahiptir.",
    kagan_sari: "Kağan Sarı, Trakya Üniversitesi Uluslararası Ticaret Bölümü devamında Dallas-Texas Elite Language Institute'de eğitim hayatını tamamlamıştır. Dynesic Technologies lojistik departmanında iş hayatına başladı. 2021 yılından beri Elliott Dalga Analizi alanında çalışmalarını sürdürerek, market trendleri ve piyasa araştırmaları doğrultusunda finans çalışmalarını sürdürmektedir.",
    baris_sahin: "Barış Şahin, Kocaeli fen lisesi akabinde Bahçeşehir üniversitesi ekonomi ve finans bölümünü derece ile bitirmiştir. 2018 yılından beri aktif olarak bir forex şirketinde yurtdışı piyasaları müdürüdür.",
  },

  basket: {
    price: "Fiyat",
    basketName: "Sepet",
    basketAdd: "Sepete Ekle",
    basketRemove: "Sepetten Çıkar",
    addedToBasket: "Sepete Eklendi",
    removedFromBasket: "Sepetten Çıkarıldı",
    basketEmpty: "Sepetiniz Boş",
    basketEmptyText: "Sepetinizde henüz ürün bulunmamaktadır.",
    basketEmptyButtonText: "Ürünleri Keşfet",
    basketTotal: "Toplam",
    basketCheckout: "Ödeme Yap",
    taxIncluded: "Kdv Dahil",
    cardCheckout: "Cart ile Ödeme Yap",
    coinPaymentscheckout: "CoinPayments İle Öde",
    alreadyExist: "Eğitim zaten sepete eklenmiş...",
    subtotal: 'Ara Toplam',
    discount: 'İndirim',
    total: 'Toplam',
    couponCode: 'Kupon Kodu',
    apply: 'Uygula'
  },

  add: 'Ekle',
  name: 'Isim',
  edit: 'Düzenle',
  cancel: 'Iptal',
  delete: 'Sil',
  approve: 'Onayla',
  approved: 'Onaylandı',
  sendAnswer: 'Cevabı Gönder',
  showMore: 'Tümümünü gör',
  questionAnswersPH: 'Bu soruya cevap vermek için bu alana yazınız..',
  askQuestionPH: "Soru sormak için bu alana yazınız..",
  questionAnswerSuccessMessage: 'Sorunuz başarıyla gönderildi. Admin onayından sonra yayınlanacaktır.',
  firstName: 'İsim',
  lastName: 'Soyisim',
  message: 'Mesaj',

  dashboard: {
    welcome: 'Yönetici paneline hoşgeldiniz. Buradan sitenizi yönetebilirsiniz.',
    controlPanel: 'Kontrol Paneli',
    users: 'Kullanıcılar',
    membershipSales: 'Üyelik Satışı',
    membershipPlans: 'Üyelik Planları',
    membershipPlansDesc: 'Bu sayfada kullanıcı planlarını yönetebilirsiniz.',
    analysis: 'Analizler',
    educations: 'Eğitimler',
    cryptoSettings: 'Kripto Ayarları',
    alarms: 'Alarmlar',
    forexSettings: 'Forex Ayarları',
    forexAlarms: 'Forex Alarmlar',
    messages: 'Mesajlar',
    totalUsers: 'Toplam Kullanıcılar',
    totalPosts: 'Toplam Blog Yazıları',
    totalMembershipSales: 'Toplam Üyelik Satışı',
    postsManagement: 'Yazılar İçerik Yönetimi',
    postsDesc: 'Bu sayfada analiz yazılarınızı yönetebilirsiniz.',
  },

  analysisPage: {
    mainForex: 'Forex',
    partities: 'Pariteler',
    stockMarket: 'Dünya Borsaları',
    corporateServices: 'Kurumsal Hizmetler',
    nseIndia:"Nse (Hindistan)",
    nikkeiJapan:"Nikkei (Japonya)",
    cac40France:"Cac40 (Fransa)",
    daxGermany:"Dax (Almanya)",
    ftseLondon:"Ftse (Ingiltere)",
    smiSwiss:"Smi (İsviçre)",
    coffee:"Kahve",
    cotton:"Pamuk",
    liveCattle:"Canlı Sığır",
    orangeJuice:"Portakal Suyu",
    corn:"Mısır",
    soybean:"Soya Fasülyesi",
    sugar:"Şeker",
    wheat:"Buğday",

    asia: 'Asya',
    europe: 'Avrupa',
    usa: 'Amerika',
    contact: 'Detaylar için iletişime geçin.'
  },

  pricingPage: {
    pricing: 'Fiyatlandırma',
    pricingPlans: 'Paketler',
    choose: 'Size en uygun paketleri seçebilirsiniz.',
    period: {
      weeks: '15 Gün',
      month: '1 Aylık',
      months: '3 Aylık',
      year: 'Yıllık'
    },
    getStarted: 'Satın Al',
    buy: 'Satın Al',
    bought: 'Satın Alındı',
    select: 'Sepete Ekle',
    selected: 'Sepete Eklendi',
    contact: 'İletişim',
    aboutPlan: '%s hakkında bilgi almak istiyorum',
    boughtSuccess: 'Başarılı. Yeni paketleri kullanabilmek için tekrar giriş yapın.'
  }
};
